<template>
	<div class="top-line mt-50-pc mt-20-m bottom">
		<div
			class="mt-50-pc mt-10-m mb-30 container"
		>
			<div class="mt-30-pc mt-20-m justify-space-between-pc gap-20">
				<div class="box radius-20 flex-1 pa-30-pc pa-15-m">
					<div class="size-px-20-pc size-px-14-m font-weight-600">문의하기</div>
					<div class="justify-space-between mt-15 items-center">
						<div class="justify-space-between gap-10 items-center"><span class="bg-primary radius-100 square-40 pa-5-pc square-25-m flex-column justify-center items-center"><img :src="require('@/assets/images/main/person.svg')" class="width-15px-m" /></span> <span class="size-px-32-pc size-px-18-m font-weight-bold">1:1문의하기</span></div>
						<div><button class="btn-round btn-outline btn-ss-m" @click="toBoard()">바로가기</button></div>
					</div>
				</div>

				<div class="box radius-20 flex-1 pa-30-pc pa-15-m mt-10-m">
					<div class="size-px-20-pc size-px-14-m font-weight-600">고객센터</div>
					<div class="justify-space-between-pc mt-15 items-center">
						<div class="justify-start gap-10 items-center"><span class="bg-primary radius-100 square-40 pa-5-pc square-25-m flex-column justify-center items-center"><img :src="require('@/assets/images/main/call.svg')" class="width-15px-m" /></span> <span class="size-px-32-pc size-px-18-m font-weight-bold"> {{ shop_info.shop_tell || '1551-5661' }}</span></div>
						<div class="line-height-150 mt-15-m">
							<div>운영시간: 평일 9시 ~ 18시</div>
							<div class="mt-5">점심시간: 12시 ~ 13시</div>
							<div class="mt-5">*공휴일, 토요일, 일요일 휴무</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-primary">
			<div class="container ptb-50-pc ptb-30-m justify-start-pc gap-50">
				<div><img :src="require(`@/assets/images${$skin.img}logo_bottom.png`)" class="logo" style="max-width: 295px"/> </div>
				<div class="color-white line-height-180 text-16 mt-10-m">
					법인명: {{ shop_info.shop_name }}
					<br/>
					대표: {{ shop_info.shop_ceo }}
					<br/>
					주소: {{ shop_info.shop_address }} {{ shop_info.shop_address_detail }}
					<br/>
					사업자번호: {{ shop_info.shop_number1 }}
					<br />
					통신판매신고번호: {{ shop_info.shop_number2 }}
					<br/>
					고객센터: {{ shop_info.shop_tell || '1551-5661' }}
					<br/>
					이메일: {{ shop_info.shop_email }}
					<br/>
				</div>
			</div>
		</div>
		<div class="bg-dark">
			<div class="container pa-10 color-white text-16 fnb-m">
				<button @click="toAuth('terms')" class="mr-10">이용 약관</button>|
				<button @click="toAuth('personal')"  class="mrl-10">개인정보 처리방침</button>|
				<button @click="toAuth('info')"  class="ml-10">이용 안내</button>
			</div>
		</div>
	</div>
</template>

<script>
export default{
	name: 'Bottom'
	, components: {}
	, props: ['cart_cnt', 'shop_info']
	,data: function(){
		return {
			items_notice: []
		}
	}
	, computed: {
		is_home: function(){
			if(this.$route.path == '/Index'){
				return true
			}else{
				return false
			}
		}
		,is_cart: function(){
			if(this.$route.path == '/Cart'){
				return true
			}else{
				return false
			}
		}
		,is_delivery: function(){
			if(this.$route.path == '/Order/List'){
				return true
			}else{
				return false
			}
		}
		,is_mypage: function(){
			if(this.$route.path.indexOf('/Account') > -1){
				return true
			}else{
				return false
			}
		}
	}
	,methods: {
		toHome: function(){
			this.$bus.$emit('to', { name: 'Index'})
		}
		,toMyPage: function(){
			this.$bus.$emit('to', { name: 'MyPage'})
		}
		,toCart: function(){
			this.$bus.$emit('to', { name: 'Cart'})
		}
		,toOrderList: function (){
			this.$bus.$emit('to', { name: 'OrderList'})
		}
		,onSide: function(){
			this.$emit('toggleSide')
		}
		, toBoard: function(){
			this.$bus.$emit('to', { name: 'BbsItem', params: { b_code: 'b_qna' }})
		}
		, toAuth: function(type){
			this.$bus.$emit('to', { name: 'Auth', params: {type: type}})
		}
	}
}
</script>

<style>
@media (max-width: 720px) {
	.fnb-m {
		font-size: 12px !important;
		font-weight: 600 !important;
		/* font-family: Pretendard; */
	}
}
</style>